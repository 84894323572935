@charset "utf-8";

@use '~/../../scss/_init.scss' as init;

$primary: init.$primary;
$secondary: init.$secondary;
$tertiary: init.$tertiary;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
$beige: #fffae2;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $tertiary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'beige': $beige
);

$enable-negative-margins: true;

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  16: $spacer * 16
);

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

// scss-docs-start font-sizes
$font-sizes: (
  lead: $font-size-base * 8,
  xl: $font-size-base * 4,
  1: $font-size-base * 2.5,
  2: $font-size-base * 2,
  3: $font-size-base * 1.75,
  4: $font-size-base * 1.5,
  5: $font-size-base * 1.25,
  6: $font-size-base,
  7: $font-size-base * .875,
  xs: $font-size-base * .7
);
// scss-docs-end font-sizes

$utilities: (
  'font-size': (
    responsive: true,
    rfs: true,
    property: font-size,
    class: fs,
    values: $font-sizes
  ),
  'flex-direction': (
    responsive: true,
    property: flex-direction,
    class: fd,
    values: row column row-reverse column-reverse
  ),
  'justify-content': (
    responsive: true,
    property: justify-content,
    class: jc,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      between: space-between,
      around: space-around,
      evenly: space-evenly
    )
  ),
  'align-items': (
    responsive: true,
    property: align-items,
    class: ai,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      baseline: baseline,
      stretch: stretch
    )
  ),
  'line-height': (
    responsive: true,
    property: line-height,
    class: lh,
    values: (
      1: 1,
      sm: 1.5,
      md: 2,
      lg: 2.5,
      xl: 4
    )
  ),
  'letter-spacing': (
    responsive: true,
    property: letter-spacing,
    class: ls,
    values: (
      0: 0,
      sm: .1em,
      md: .2em,
      lg: .3em,
      xl: .4em
    )
  ),
  'width': (
    responsive: true,
    property: width,
    class: w,
    values: (
      5: 5%,
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 90%,
      95: 95%,
      100: 100%,
      auto: auto
    )
  ),
  'height': (
    responsive: true,
    property: height,
    class: h,
    values: (
      5: 5%,
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 90%,
      95: 95%,
      100: 100%,
      auto: auto
    )
  )
);

@import 'bootstrap/scss/bootstrap.scss';
